.slide-container {
  width: 60%; /* Reduced width */
  height: 300px; /* Set specific height */
  overflow: hidden; /* Hide overflowing slides */
  position: relative; /* Ensure container position for smooth transition */
  float: left; /* Align slide container to the left */
}

.slide-content {
  display: flex; /* Flex layout for slide transition */
  transition: transform 0.5s ease-in-out; /* Smooth transition effect */
  width: 100%;
}

.slide {
  min-width: 100%;
  box-sizing: border-box;
}

.slide-image,
.slide-video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 600px){
  {
    
  }
}
